/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Menu, MenuButton, MenuWrapBox, Button, MenuButtonClose, Text, Clearfix, Image, Fullmap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Dárkové poukazy"} description={"MIRA studio - DÁRKOVÉ POUKAZY"}>
        <Column className="css-1c6uie2 --left bg--center" style={{"paddingTop":108,"marginBottom":0,"paddingBottom":43,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"vb6rsx31h7i"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4 title-box--center ff--3 fs--48 w--500 lh--2" style={{"marginTop":27.693748474121097,"paddingTop":0,"marginBottom":1,"paddingBottom":0}} content={"MIRA studio"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--18" style={{"marginTop":0,"paddingTop":0}} content={"masáže &amp; kosmetika"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--22 ls--12" style={{"paddingLeft":17,"paddingBottom":30.46875}} content={"<span style=\"font-style: normal;\">SIMONA BOHÁČOVÁ</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--12 pt--12" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} menu={true}>
          
          <Menu className="--full" fullscreen={true}>
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--center">
              
              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/"} target={""} content={"<span style=\"font-weight: 700; color: var(--black);\">HLAVNÍ STRÁNKA</span>"}>
              </Button>

              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"https://mira-studio.cz/masaze"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">MASÁŽE</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} url={"https://mira-studio.cz/kosmetika"} href={"https://mira-studio.cz/kosmetika"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">KOSMETIKA</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/darkovepukazy"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">DÁRKOVÉ POUKAZY</span>"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0,"backgroundColor":"var(--color-main-bg-2)"}} name={"uow7u16f1t"}>
          
          <ColumnWrap className="column__flex el--2 flex--stretch" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} columns={"2"}>
          </ColumnWrap>

        </Column>


        <Column className="--invert" style={{"marginTop":0,"paddingTop":null,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(235,214,139,1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" style={{"marginTop":0,"marginBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":10.92498779296875}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":342}} content={"<a href=\"https://www.facebook.com/mirastudiobrno\" style=\"color: var(--black); font-weight: bold;\">Sledujte MIRA studio na&nbsp; FACEBOOKU</a>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Přečtěte si něco zajímavého nejen ze světa MIRA studia, ale z oblasti masáží a kosmetiky celkově.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":6}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":280}} content={"<span style=\"color: var(--black); font-weight: bold;\"><a href=\"https://www.instagram.com/mirastudio_brno/?hl=cs\">Každý den v kontaktu na&nbsp; INSTAGRAMU</a></span>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Pokud rádi sledujete stories a zapojujete se do obsahu, tohle je místo pro Vás! Navíc zde vždy zveřejňuji last-minute uvolněné termíny.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingTop":28,"marginBottom":16,"paddingBottom":14.350006103515623}}>
              
              <Title className="title-box title-box--style2 ff--3 fs--18 title-box--invert" style={{"maxWidth":280}} content={"<span style=\"color: var(--black); font-weight: bold;\"><a href=\"https://www.google.com/search?q=mira+studio&amp;client=opera&amp;hs=i0t&amp;sxsrf=APwXEdcv9CkCNmTCExb5JMuz5d5UOoXzoQ%3A1687005195104&amp;ei=C6iNZKT0BdCV9u8PntasiAM&amp;ved=0ahUKEwjkpY2LqMr_AhXQiv0HHR4rCzEQ4dUDCA4&amp;uact=5&amp;oq=mira+studio&amp;gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECCMQJzINCC4QrwEQxwEQigUQJzIHCCMQigUQJzIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIICAAQgAQQywEyCAgAEIAEEMsBMggIABCABBDLATIICAAQgAQQywE6CwgAEIAEELEDEIMBOgcIABCKBRBDOgsILhCKBRCxAxCDAToHCC4QigUQQzoLCAAQigUQsQMQgwE6CwguEIAEELEDEIMBOhEILhCABBCxAxCDARDHARCvAToICAAQgAQQsQM6BQguEIAEOgsILhCABBDHARCvAToLCC4QgwEQsQMQgAQ6BQgAEIAEOgsILhCvARDHARCABEoECEEYAFAAWLoYYLobaABwAXgBgAHbAYgBzQySAQUxLjkuMZgBAKABAcABAQ&amp;sclient=gws-wiz-serp#lrd=0x47733399a837f975:0xe8cf48225afd9e4b,1,,,,\">Přečtěte si recenze na GOOGLU</a></span>"}>
              </Title>

              <Text className="text-box ff--3 fs--12 text-box--invert" content={"<span style=\"color: var(--black); font-style: italic;\">Podívejte se, jaké hodnocení mi napsali zákazníci a zákaznice a jak byli s mojí službou spokojeni.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <Clearfix >
          </Clearfix>

        </Column>


        <Column className="--center" style={{"paddingTop":24,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"h66dbzg9su"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":1165}}>
              
              <Title className="title-box title-box--center ff--3" content={"<span style=\"font-weight: bold;\">Dárkový poukaz jako perfektní dárek</span>"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--14" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-style: italic;\">K narozeninám, jmeninám, Vánocům, k promoci nebo jen tak pro radost! <br>Pro rodiče, děti, prarodiče, kamarády a kolegy.</span><br>S dárkovým poukazem na relaxační masáž nebo luxusní kosmetické ošetření nešlápnete nikdy vedle!&nbsp;<br><br><span style=\"font-weight: bold;\">Poukaz Vám vystavím na jakoukoliv službu z nabídky masáží a kosmetiky dle ceníku. </span><br>Platnost je 6 měsíců od data zakoupení.&nbsp;<br>Platnost poukazu lze prodloužit za cenu 100,- za každý další měsíc.<br><br>Možnost platby hotovostí a předání osobně nebo převodem na účet a předání elektronickou formou (e-mail).<br><br><span style=\"font-weight: bold;\">Nevíte-li si rady s výběrem, ráda Vám poradím!</span><br>Stačí jen zavolat.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"ns8lk7zlpd"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"marginBottom":0,"paddingBottom":3.7874755859375}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/c8a4390a45154fd481d7838c0167ca85_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/c8a4390a45154fd481d7838c0167ca85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/c8a4390a45154fd481d7838c0167ca85_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/c8a4390a45154fd481d7838c0167ca85_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/c8a4390a45154fd481d7838c0167ca85_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/c8a4390a45154fd481d7838c0167ca85_s=2000x_.jpg 2000w"} position={{"x":"-32.76543209876543%","y":"-0.6172839506172839%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/8b80e376e4a64387ba5570f9fa29cd4c_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/8b80e376e4a64387ba5570f9fa29cd4c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/8b80e376e4a64387ba5570f9fa29cd4c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/8b80e376e4a64387ba5570f9fa29cd4c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/8b80e376e4a64387ba5570f9fa29cd4c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/3a67795b71be4742801313569d53d768_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/3a67795b71be4742801313569d53d768_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/3a67795b71be4742801313569d53d768_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/3a67795b71be4742801313569d53d768_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/3a67795b71be4742801313569d53d768_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"KONTAKTNÍ ÚDAJE A ADRESA:"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--invert" style={{"paddingTop":34,"marginBottom":0,"paddingBottom":21.681243896484375,"backgroundColor":"var(--color-main-bg-2)"}} name={"97kd8e0pha"}>
          
          <ColumnWrap className="column__flex el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":181,"marginBottom":0,"paddingBottom":4.5}} src={"https://cdn.swbpg.com/t/2871/a90f39d686814eefbc682c74343ef207_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/2871/a90f39d686814eefbc682c74343ef207_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--3 fs--24 title-box--invert" content={"MIRA studio&nbsp;"}>
              </Title>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--10" style={{"maxWidth":400}} content={"IČO: 03616568<br><br>kontaktní osoba: <br><span style=\"font-style: italic;\">Simona Boháčová</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--left ff--3 fs--16 subtitle-box--invert" content={"Kontakt:"}>
              </Subtitle>

              <Text className="text-box text-box--left ff--3 fs--18 w--500 text-box--invert mt--02" content={"+420 736 150 609"}>
              </Text>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--0 pt--0" content={"facebook: @mirastudiobrno<br>"}>
              </Text>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.facebook.com/mirastudiobrno/"} content={"FACEBOOK"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 ff--3 fs--16 subtitle-box--invert" content={"Provozovna:"}>
              </Subtitle>

              <Text className="text-box ff--3 fs--18 w--500 text-box--invert mt--02" content={"Lidická 38/1874\n<br>Brno 602 00"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--13 w--300 text-box--invert mt--0 pt--0" content={"Od Hlavního nádraží tramvají č.1, směr Řečkovice, zastávka Antonínská \nParkování ZDARMA ve dvoře, parkovací čip Vám zapůjčím.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"Jsme na Google mapách!"}>
              </Title>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.google.cz/maps/place/MIRA+studio+-+masáže+a+relaxace/@49.2113373,16.5891307,16.22z/data=!4m5!3m4!1s0x47733399a837f975:0xe8cf48225afd9e4b!8m2!3d49.2113978!4d16.5920673"} content={"ZDE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"v2lxtrsj83h"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Fullmap name={"enqpd7znz0j"}>
        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}